<template>
  <div class="grid">
    <div class="col-12">
      <div v-if="auth.hasRoleConsignatariaAdmin() || auth.hasRoleRenegociar()">
        <Panel header="Renegociação" class="mt-3">
          <div>
            <Toolbar class="mb-4">
              <template #start>
                <Button
                  label="Voltar"
                  icon="pi pi-chevron-left"
                  class="mt-2 mr-2 p-button-sm p-button-secondary"
                  @click="$router.back()" />
              </template>
            </Toolbar>
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <PickList
                  v-model="consignacoes"
                  listStyle="height:342px"
                  dataKey="id"
                  :stripedRows="true"
                  :loading="loading">
                  <template #sourceheader> Disponível </template>
                  <template #targetheader> Selecionado </template>
                  <template #item="consignacao">
                    <div class="consgnacao-item">
                      <div class="consignacao-list-detail">
                        <b
                          >{{ consignacao.item.rubrica.rubrica }} |
                          {{ consignacao.item.rubrica.nome }}</b
                        >
                        <div class="consignacao-detail">
                          <b>N° Contrato: </b>
                          {{ consignacao.item.numeroContrato }}
                        </div>
                        <div class="consignacao-detail">
                          <b>Valor R$</b> {{ consignacao.item.valorParcela }}
                        </div>
                        <div class="consignacao-detail">
                          <b>Prazo Total:</b> {{ consignacao.item.prazoTotal }}
                        </div>
                        <div class="consignacao-detail">
                          <b>Prazo Restante:</b>
                          {{ consignacao.item.prazoRestante }}
                        </div>
                        <div class="consignacao-detail">
                          <b>Status:</b>
                          {{
                            exibirNomeFormatadoStatusConsignacao(
                              consignacao.item.statusConsignacao,
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </template>
                </PickList>
              </div>
            </div>
            <div class="p-fluid formgrid grid">
              <div class="field col-3 md:col-3">
                <label for="valorParcela"
                  ><b>Valor total a ser comprado:</b></label
                >
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">R$</span>
                  <InputNumber
                    id="valorParcela"
                    v-model="somarValoresDasConsignacoesSelecionadas"
                    mode="decimal"
                    locale="pt-BR"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                    placeholder="0,00"
                    disabled />
                </div>
              </div>
            </div>
            <div>
              <proposta-form
                :isSalvarOk="isSalvarOk"
                :tipo="tipo"
                :metodoSalvar="salvar"
                :totalSomaValoresConsignacoesSelecionadas="
                  totalSomaValoresConsignacoesSelecionadas
                "
                :usaSenha="false"></proposta-form>
            </div>
          </div>
        </Panel>
      </div>
      <div v-else>
        <Panel header="Renegociação Online" class="mt-3">
          <h6>
            {{ auth.msgPermissao() }}
          </h6>
        </Panel>
      </div>
    </div>
    <ProgressSpinner
      v-if="loadingSpinner"
      class="loading"
      style="width: 50px; height: 50px" />
  </div>
</template>

<script>
import PropostaForm from '@/components/shared/consignacao/propostaForm.vue'
import ConsignacaoService from '@/service/ConsignacaoService.js'
import CompraDividaService from '@/service/CompraDividaService.js'
import CompraDivida from '@/domain/CompraDivida.js'
import { consignatariaStore } from '@/stores/consignataria'
import auth from '@/modules/auth'

export default {
  components: {
    'proposta-form': PropostaForm,
  },

  setup() {
    const storeConsignataria = consignatariaStore()
    return { storeConsignataria }
  },

  data() {
    return {
      compraDivida: new CompraDivida(),
      consignacoes: null,
      matricula: {
        id: this.$route.params.id,
      },
      totalSomaValoresConsignacoesSelecionadas: 0,
      loading: false,
      isSalvarOk: false,
      auth,
      loadingSpinner: false,
    }
  },

  computed: {
    somarValoresDasConsignacoesSelecionadas() {
      return this.realizarSomaDosValoresConsignacoesSelecionadas()
    },
    tipo() {
      return 'RENEGOCIACAO'
    },
    consignacoesSelecionadas() {
      return this.consignacoes[1]
    },
  },

  created() {
    this.consignacaoService = new ConsignacaoService(this.$http)
    this.compraDividaService = new CompraDividaService(this.$http)
  },

  mounted() {
    this.carregarConsignacoes()
  },

  methods: {
    carregarConsignacoes() {
      this.loading = true
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )

      this.loading = true
      this.consignacaoService
        .getListaConsignacoesAtivasPorConsignatariaLogadaEMatricula(
          this.matricula.id,
          consignataria.id,
        )
        .then(
          (res) => {
            if (Array.isArray(res) && !res.length > 0) {
              this.exibeToast('empty')
            }
            this.consignacoes = [res, []]
            this.loading = false
          },
          (err) => {
            if (err) {
              this.exibeToast(
                'error-lista-consiganacoes',
                err.response.data.message,
              )
            }
          },
        )
    },

    exibirNomeFormatadoStatusConsignacao(statusConsignacao) {
      if (statusConsignacao === 'PENDENTE') {
        return 'Pendente'
      } else if (statusConsignacao === 'ATIVA') {
        return 'Ativa'
      } else if (statusConsignacao === 'SUSPENSA') {
        return 'Suspensa'
      } else if (statusConsignacao === 'CANCELADA') {
        return 'Cancelada'
      } else if (statusConsignacao === 'QUITADA') {
        return 'Quitada'
      } else if (statusConsignacao === 'JUDICIAL') {
        return 'Judicial'
      } else if (statusConsignacao === 'QUITACAO') {
        return 'Quitação'
      } else if (statusConsignacao === 'SOLICITADO_CANCELAMENTO') {
        return 'Cancelada'
      } else if (statusConsignacao === 'SOLICITADA_SUSPENSAO') {
        return 'Suspensa'
      } else if (statusConsignacao === 'MOVIMENTO_PARCIAL') {
        return 'Mov. Parcial'
      } else if (statusConsignacao === 'AGUARDANDO_SUPERVIDOR') {
        return 'Aguardando Supervisor'
      } else if (statusConsignacao === 'NAO_APROVADA_SUPERVISOR') {
        return 'Não Aprovada Supervisor'
      } else if (statusConsignacao === 'BAIXADA') {
        return 'Baixada'
      } else if (statusConsignacao === 'NAO_DESCONTADA') {
        return 'Não Descontada'
      } else if (statusConsignacao === 'FORA_MARGEM') {
        return 'Fora da Margem'
      } else if (statusConsignacao === 'DESCONTO_A_MENOR') {
        return 'Desc. a Menor'
      }
    },

    realizarSomaDosValoresConsignacoesSelecionadas() {
      if (this.consignacoes) {
        this.totalSomaValoresConsignacoesSelecionadas =
          this.consignacoesSelecionadas.reduce((totalSoma, item) => {
            return totalSoma + item.valorParcela
          }, 0)
      }

      return this.totalSomaValoresConsignacoesSelecionadas
    },

    async salvar(proposta) {
      if (this.consignacoesSelecionadas.length === 0) {
        this.exibeToast('empty-select-list')
      } else {
        this.atribuirDados(proposta)
        this.loadingSpinner = true

        let res = await Promise.resolve(
          this.compraDividaService.save(this.compraDivida),
        ).catch((err) => {
          this.exibeToast('error', err.response.data.message)
          this.tratarCarencia()
          this.saving = false
        })
        this.loadingSpinner = false
        return typeof res === 'undefined' ? 'error' : res.data.proposta
      }
    },

    atribuirDados(proposta) {
      this.compraDivida.consignacoes = this.consignacoesSelecionadas
      this.compraDivida.proposta = proposta
    },

    exibeToast(tipo, msg) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Renegociação Online realizada com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'empty') {
        this.$toast.add({
          severity: 'error',
          summary: 'Não há consignações para serem renegociadas.',
          life: 10000,
        })
      } else if (tipo === 'error-lista-consiganacoes') {
        this.$toast.add({
          severity: 'error',
          summary:
            'Erro ao tentar carregar consignações. Entre em contato com o suporte.',
          life: 10000,
        })
      } else if (tipo === 'empty-select-list') {
        this.$toast.add({
          severity: 'error',
          summary:
            'É necessário selecionar ao menos uma consignação para realizar uma renegociação de proposta online.',
          life: 10000,
        })
      }
    },

    limparCampos() {
      this.compraDivida = new CompraDivida()
      this.consignacoes[1] = []
      this.carregarConsignacoes()
    },
  },
}
</script>

<style scoped>
consignacao-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.consignacao-list-detail {
  flex: 1 1 0;
}

.consignacao-detail {
  vertical-align: middle;
  line-height: 1;
}

::v-deep .p-picklist-source-controls {
  display: none;
}

::v-deep .p-picklist-target-controls {
  display: none;
}

@media screen and (max-width: 576px) {
  .consignacao-item {
    flex-wrap: wrap;
  }
}

h6 {
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #c82333;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
</style>
